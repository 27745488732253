// Vendors
import { push } from 'connected-react-router';
//helpers
import { to } from '../../../lib/helpers';

// Requests
import { lookupAppLinkRequest } from '../../../backend/requests';

import { APP_TRANSACTION_IDS, clearAppData, storeBasicInfo } from '../../app/appActions';

export const TPWD_TPWDAS_CLEAR_DATA = '[TPWD] TPWDAS - Clear Data';
export const TPWD_MANAGE_ASSET = '[TPWD] Manage Asset';
export const TPWD_ADDITIONAL_ID_CARD_SELECTED = 'ADDITIONAL ID CARDS';
export const TPWD_ADDITIONAL_ID_CARD_QUANTITY = 'ADDITIONAL ID CARD QUANTITY';
export const TPWD_SELECTED_CATALOG_ITEMS = 'SELECTED CATALOG ITEMS';
export const TPWD_UPDATE_FEE_DETAILS = '[TPWD] UPDATE FEE DETAILS';

export const manageTPWDAsset = data => {
  return async function (dispatch) {
    await dispatch(storeBasicInfo(data));
    await dispatch(storeSelected(data));
    dispatch(push('/tpwdas'));
  };
};

export function storeSelected(data) {
  return {
    type: TPWD_MANAGE_ASSET,
    payload: data,
  };
}

export const clearTPWDASData = () => dispatch => {
  dispatch(clearAppData());
  return dispatch({ type: TPWD_TPWDAS_CLEAR_DATA });
};

export function setAdditionalIdCardSelected(data) {
  return {
    type: TPWD_ADDITIONAL_ID_CARD_SELECTED,
    payload: data,
  };
}

export function storeAdditionalIdCardQuantity(data) {
  return {
    type: TPWD_ADDITIONAL_ID_CARD_QUANTITY,
    payload: data,
  };
}

export function setSelectedCatalogItemTypes(data) {
  return {
    type: TPWD_SELECTED_CATALOG_ITEMS,
    payload: data,
  };
}

export const updateFeeDetails = payload => {
  return {
    type: TPWD_UPDATE_FEE_DETAILS,
    payload,
  };
};

export const getTPWDFeeDetails = feeDetailsRequest => {
  return async function (dispatch, getStore) {
    const store = getStore();
    const { userKey, appId, selectedCatalogItemTypes, additionalIdCardQuantity } = store.tpwd.selected;

    const [{ body }, err] = await to(
      dispatch(
        lookupAppLinkRequest({
          transactionInfo: feeDetailsRequest,
          appId: appId,
          userKeys: [userKey],
          transactionId: APP_TRANSACTION_IDS.FEE_CALCULATION,
        })
      )
    );
    if (err) return Promise.reject(err);
    dispatch(updateFeeDetails({ ...body.data }));
    return Promise.resolve();
  };
};

export const formatTPWDPayment = () => {
  return async function (dispatch, getStore) {
    const { tpwd, user } = getStore();
    const { profile: contDetails } = user;
    const { selectedCatalogItemTypes, additionalIdCardQuantity, updatedFeeDetails = {} } = tpwd;
    const { userKey, appId, agencyId, vin, link: { profile: { account } } } = tpwd.selected;
    const [accountDetails] = account;
    const { primaryOwnerAddress } = accountDetails;
    const userKeys = [userKey];
    const contactDetails = {
      firstName: contDetails.fName,
      middleName: '',
      lastName: contDetails.lName,
      suffix: '',
      entity: contDetails.businessName ? contDetails.businessName : '',
      customerType: contDetails.addressType,
      email: contDetails.userEmail,
      phone: contDetails.phoneNum,
      // imcNo: dmv.appProfile.imcNo ? dmv.appProfile.imcNo : '',
      mailingAddress: {
        address1: primaryOwnerAddress.address1,
        address2: primaryOwnerAddress.address2,
        city: primaryOwnerAddress.city,
        postalCode: contDetails.postalCode,
        stateProvince: primaryOwnerAddress.stateProvince,
      },
    };

    const assetDetails = {
      assetType: accountDetails.assetType,
      txNumber: accountDetails.txNumber,
      modelYear: accountDetails.modelYear,
      make: accountDetails.make,
      hullTtype: accountDetails.hullTtype,
      length: accountDetails.length,
      expirationDate: accountDetails.expirationDate,
      additionalIdCardsQuantity: additionalIdCardQuantity,
      selectedCatalogItemTypes: selectedCatalogItemTypes.map((catalog) => catalog.code),
    };

    const feeDetails = [];
    updatedFeeDetails.paymentCatalogItems.forEach((fee) => {
      const feeDetail = {};
      feeDetail.feeCode = fee.code;
      feeDetail.feeDescription = fee.description;
      feeDetail.feeAmount = fee.unitPrice;
      if(fee.code === 'SPDON' && updatedFeeDetails.donationSelected)
      {
      feeDetails.push(feeDetail);}
      else if(fee.code !== 'SPDON') {
        feeDetails.push(feeDetail);}
    })
    const boatRenewalFee = updatedFeeDetails.paymentCatalogItems.filter((fee) => fee.code !== 'SPDON').reduce((accumulator, currentValue) => {
      return accumulator + currentValue.unitPrice
    }, 0)
    const parksDonationFee = updatedFeeDetails.paymentCatalogItems.filter((fee) => fee.code === 'SPDON')[0].unitPrice;
    const transactionInfo = {
      ...assetDetails,
      contactDetails: contactDetails,
      transactionAmounts: {
        boatRenewalFee: boatRenewalFee,
        boatRenewalOption: true,
        feeDetails,
        subTotal: updatedFeeDetails.donationSelected ?
          updatedFeeDetails.totalFeeWithDonation : updatedFeeDetails.totalFeeWithOutDonation,
        handlingFeeOption: true,
        handlingFee: updatedFeeDetails.donationSelected ?
          updatedFeeDetails.convenienceFeeWithDonation
          : updatedFeeDetails.convenienceFeeWithOutDonation,
        parksDonationFee: updatedFeeDetails.donationSelected ? parksDonationFee : undefined,
        parksDonationOption: updatedFeeDetails.donationSelected ? true : undefined,
      }
    };

    return {
      userKeys,
      appId,
      agencyId,
      transactionId: APP_TRANSACTION_IDS.SUBMIT_CREATE_PAYMENT,
      transactionInfo,
    };
  };
};
