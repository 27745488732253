//@flow

import { REQUEST_TYPES, REQUEST_LOCATIONS } from '.';

export const getPaymentAccountsRequest = (): RequestOptions => {
  const pathArr = window.location.pathname.split('/');

  //add app argument only for dmv or tpwd
  const app = pathArr.find(path => (path === 'dmvrr'|| path === 'tpwdas'))
  return {
    type: `[API] Payment Request`,
    url: app ? `/user/ui/wallet?app=${app}` : `/user/ui/wallet`,
    APIMethod: REQUEST_TYPES.GET,
  };
};

export const getAddPaymentAccountFrameUrlRequest = (): RequestOptions => ({
  type: `[API] Get iFrame URL for Adding Payment Method`,
  url: `/user/ui/wallet/paymentAccount/authorize`,
  APIMethod: REQUEST_TYPES.GET,
});

export const getEditPaymentAccountFrameUrlRequest = (body: {
  paymentAccountId: string,
}): RequestOptions => ({
  type: `[API] Get iFrame URL for Editing Payment Method`,
  url: `/user/ui/wallet/paymentAccount/edit`,
  APIMethod: REQUEST_TYPES.POST,
  body,
});

export const getPaymentFormFrameUrlRequest = (): RequestOptions => {
  const pathArr = window.location.pathname.split('/');

  //add app argument only for dmv
  const app = pathArr.find(path => path === 'dmvrr');
  return {
    type: `[API] Get iFrame URL for the Payment Form`,
    url: app
      ? `/user/ui/wallet/paymentForm?app=${app}`
      : `/user/ui/wallet/paymentForm`,
    APIMethod: REQUEST_TYPES.GET,
  };
};

// TODO: Remove IP calls and environment variables from the project since it is no longer used
export const clientIPRequest = () => ({
  type: `[API] Client IP Request`,
  location: REQUEST_LOCATIONS.IP,
  APIMethod: REQUEST_TYPES.GET,
});

export const deletePaymentAccountRequest = (
  paymentAccount: PaymentAccount
): RequestOptions => ({
  type: `[API] Delete Payment Account`,
  url: `/user/ui/wallet/paymentAccount/delete`,
  APIMethod: REQUEST_TYPES.POST,
  body: paymentAccount,
});
